<template>
  <div class="d-flex">
    <svg
      class="m-auto"
      :width="width"
      :height="height"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.93 0.929993C1.12 2.73999 0 5.23999 0 7.99999C0 10.76 1.12 13.26 2.93 15.07L4.34 13.66C2.89 12.22 2 10.22 2 7.99999C2 5.78999 2.89 3.77999 4.34 2.33999L2.93 0.929993ZM17.07 0.929993L15.66 2.33999C17.11 3.77999 18 5.78999 18 7.99999C18 10.22 17.11 12.22 15.66 13.66L17.07 15.07C18.88 13.26 20 10.76 20 7.99999C20 5.23999 18.88 2.73999 17.07 0.929993ZM5.76 3.75999C4.67 4.84999 4 6.34999 4 7.99999C4 9.64999 4.67 11.15 5.76 12.24L7.17 10.83C6.45 10.11 6 9.10999 6 7.99999C6 6.88999 6.45 5.88999 7.17 5.16999L5.76 3.75999ZM14.24 3.75999L12.83 5.16999C13.55 5.88999 14 6.88999 14 7.99999C14 9.10999 13.55 10.11 12.83 10.83L14.24 12.24C15.33 11.15 16 9.64999 16 7.99999C16 6.34999 15.33 4.84999 14.24 3.75999ZM10 5.99999C9.46957 5.99999 8.96086 6.21071 8.58579 6.58578C8.21071 6.96085 8 7.46956 8 7.99999C8 8.53043 8.21071 9.03913 8.58579 9.41421C8.96086 9.78928 9.46957 9.99999 10 9.99999C10.5304 9.99999 11.0391 9.78928 11.4142 9.41421C11.7893 9.03913 12 8.53043 12 7.99999C12 7.46956 11.7893 6.96085 11.4142 6.58578C11.0391 6.21071 10.5304 5.99999 10 5.99999Z"
        :fill="color"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'LiveIcon',
  props: {
    width: {
      type: Number,
      default: 24,
    },
    height: {
      type: Number,
      default: 20,
    },
    color: {
      type: String,
      default: 'var(--settings-registration-current-event-button-background)',
    },
  },
};
</script>
